<template>
  <div>
    <!-- 村村通户内验收导入 -->
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          <el-form style="width: 100%" :inline="true">
          <el-form-item label="工程编号/名称:" style="padding:0px"> 
          
            <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.searchText"
            ></el-input>
            </el-form-item>

            <span style="margin-right: 20px"></span>
            <el-form-item label="安装施工队:" style="padding:0px"> 
            
            <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.install"
            ></el-input>
            </el-form-item>
            <span style="margin-right: 20px"></span>
           
            <el-form-item label="现场管理员:" style="padding:0px"> 
            <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.siteManager"
            ></el-input>
            </el-form-item>
            
            <span style="margin-right: 20px"></span>
             <el-form-item label=" 操作人:" style="padding:0px"> 
           
            <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.userName"
            ></el-input>
            </el-form-item>
            <span style="margin-right: 20px"></span>
            <el-form-item label=" 操作时间:" style="padding:0px"> 
            
            <el-date-picker
              size="small"
              v-model="searchBox.time"
              type="datetimerange"
              style="width: 400px"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            </el-form-item>
            <span style="margin-right: 20px"></span>
            <el-form-item label="" style="padding:0px" label-width="0px"> 
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="search"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="SearchBtn"
              size="small"
              @click="replacement"
              >重置</el-button
            >
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <div style="display: flex">
            <el-button class="SearchBtn" size="small" @click="deriveExcel"
              >导入</el-button
            >
            <el-button class="SearchBtn" size="small" @click="downloadempty"
              >导出空白模板</el-button
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 460px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="install"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createBy"
              label="操作人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="操作时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="操作" align="center" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="seeDetil(row)"
                  >查看</el-button
                >
                <el-button  v-if="$anthButtons.getButtonAuth('cctdrsc')"               
                 type="danger"
                  plain
                  size="mini"  @click="delDetil(row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="历次验收信息"
      :visible.sync="detilDialog"
      width="65%"
      :close-on-click-modal="false"
    >
      <el-form ref="project" :model="project" size="mini">
        <p class="detil-code">验收工程</p>
        <el-row>
          <el-col :span="24">
            <el-form-item label="工程名称：" prop="positionCode">
              {{ dataList.projectName }}
            </el-form-item>
          </el-col>
        </el-row>
        <p class="detil-code">导入明细</p>
        <!--数据表格-->
        <el-table
          ref="tableData"
          :data="dataList.details.records"
          style="width: 100%; margin: 15px 0"
          border
          :stripe="true"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
        >
          <el-table-column
            prop="realname"
            label="姓名"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="idNumber"
            label="身份证号"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="联系电话"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="address"
            label="地址"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="gradeNumber"
            label="表编号"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="directionName"
            label="表向"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="typeName"
            label="燃气类型"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="venderName"
            label="厂家"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="titles"
            label="表封号"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            :current-page="searchInfo.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="searchInfo.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="dataList.details.total"
            @size-change="handleSizeChangeInfo"
            @current-change="handleCurrentChangeInfo"
          >
          </el-pagination>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      title="导入"
      :visible.sync="deriveDialog"
      width="25%"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form ref="project" :model="project" :rules="rule" size="mini">
        <el-row>
          <el-col :span="24">
            <el-form-item label="工程名称：" prop="workId">
              <el-select
                v-model="project.workId"
                clearable
                filterable 
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="选择excel文件：">
              <span slot="label">
                <span style="color: red; margin-left: 4px">*</span>
                选择excel文件：
              </span>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreviewExcel"
                :on-remove="handleRemoveExcel"
                :before-remove="beforeRemoveExcel"
                :http-request="repairBeforUploadFun1"
                multiple
                :limit="1"
                ref="uploadDemo"
                accept=".xls,.xlsx"
                :on-exceed="handleExceedExcel"
                :file-list="fileListExcel"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  v-if="uploadEexcel"
                  style="color: red"
                >
                  只能上传.xls,.xlsx文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="msg">
          <el-col :span="24">
            <el-form-item label="导入情况说明：" prop="positionCode">
            </el-form-item>
          </el-col>
          <el-col>
            <div style="color: red">
              共计导入{{
                msg
              }}条有效信息；表编号为空及系统中已存在的，不做处理；
            </div>
          </el-col>
        </el-row>
        <el-row v-if="msg_error">
          <el-col :span="24">
            <el-form-item label="导入问题数据行号：" prop="positionCode">
            </el-form-item>
          </el-col>
          <el-col>
            <div style="color: red;width: 420px;height: 100px;overflow: auto;border: 1px solid;">
              {{msg_error}}
            </div>
          </el-col>
        </el-row>
        <el-button type="primary" size="small" @click="resetData" v-if="!msg"
          >确认导入</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVillage,
  prepare_getWorkProject,
  indoor_intoVillage,
  indoor_getFileRecord,
  indoor_getRecordDetails,
  deleteData,
} from "@/RequestPort/intranet";
import { json } from "body-parser";
import getNowFormatDate from "./../../common/js/nowDate.js";
export default {
  
  name: "lockout",
  data() {
    return {
      code:"",
      status: "12",
      activeName: "1",
      project: {
        urlList: [
          "https://gas-dev.oss-cn-beijing.aliyuncs.com/gas_patrol_484857109813395456.png",
          "https://gas-dev.oss-cn-beijing.aliyuncs.com/gas_patrol_482242746828980224.png",
        ],
        checkList: [],
      },
      searchBox: {
        current: 1,
        size: 100,
        searchText: "",
        install: "",
        siteManager: "",
        userName: "",
      },
      total: 0,
      detilDialog: false,
      deriveDialog: false,
      typeList: [
        {
          label: "合格",
          value: 0,
        },
        {
          label: "不合格",
          value: 1,
        },
        {
          label: "未出结果",
          value: 2,
        },
      ],
      rule: {
        workId: {
          required: true,
          message: "请选择工程名称",
          trigger: "change",
        },
        positionCode: {
          required: true,
          message: "请上传文件",
          trigger: "change",
        },
      },
      
      tableData: [{}],
      projectList: [],
      repairBeforFileFormData1: new FormData(),
      fileListExcel: [],
      uploadEexcel: false,
      dataList: {
        details: {},
      },
      searchInfo: {
        current: 1,
        size: 100,
      },
      // 导入结果
      msg: "",
      msg_error:'',
    };
  },
  mounted() {
    this.coder = localStorage.getItem("roleCodes");
    console.log(this.$refs.SearchBar.offsetHeight);
    this.loadList(this.searchBox);
    this.getOption();
  },
  methods: {
    // 关闭事件
    close() {
      this.project = {
        workId: "",
        flag: "",
        urlList: [],
        checkList: [],
      };
      (this.objData = {}), (this.repairBeforFileFormData1 = new FormData());
      this.fileListExcel = [];
      this.uploadEexcel = false;
      this.loadList(this.searchBox);
      this.msg = ''
      this.msg_error = ''
    },
    replacement() {
      this.searchBox = {
        current: 1,
        size: 100,
      };
      this.loadList(this.searchBox);
    },
    // 打开导入
    deriveExcel() {
      if (this.$refs.project) {
        this.$refs.project.resetFields();
      }
      this.msg = "";
      this.deriveDialog = !this.deriveDialog;
    },
    // 工程名称
    getOption() {
      prepare_getWorkProject({ flag: 11 }).then((res) => {
        this.projectList = res.data;
      });
    },
    handleRemovePhone(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreviewPhone(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 移除列表数据
    handleRemoveExcel(file, fileList) {
      this.repairBeforFileFormData1 = new FormData();
      this.fileListExcel = [];
    },
    handlePreviewExcel(e) {
      console.log(e);
    },
    repairBeforUploadFun1(e) {
      this.repairBeforFileFormData1 = new FormData();
      this.repairBeforFileFormData1.append("file", "value");
      this.fileListExcel.push(e.file);
      this.repairBeforFileFormData1.set("file", e.file);
      this.uploadEexcel = false
      console.log(this.repairBeforFileFormData1.get("file"));
    },
    handleExceedExcel(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemoveExcel(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`, {
        closeOnClickModal: false,
      });
    },

    // 详情
    delDetil(row) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
       
        deleteData({ id: row.id }).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });

          this.search();

        });
      });
    },

    // 详情
    seeDetil(row) {
      this.searchInfo = {
        recordId: row.id,
        current: 1,
        size: 100,
        workId: row.workId,
        flag: 3,
      };
      indoor_getRecordDetails({
        recordId: row.id,
        current: 1,
        size: 100,
        workId: row.workId,
        flag: 3,
      }).then((res) => {
        this.dataList = res.data;
      });

      this.detilDialog = true;
    },
    handleSizeChangeInfo(val) {
      this.searchInfo.current = 1;
      this.searchInfo.size = val;
      this.loadListInfo();
    },
    handleCurrentChangeInfo(val) {
      this.searchInfo.current = val;
      this.loadListInfo();
    },
    loadListInfo() {
      indoor_getRecordDetails(this.searchInfo).then((res) => {
        this.dataList = res.data;
      });
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      if (this.searchBox.time) {
        this.searchBox.startDate = this.searchBox.time[0];
        this.searchBox.endDate = this.searchBox.time[1];
      }
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    // 加载列表
    loadList() {
      this.searchBox.fileSource = 3;
      indoor_getFileRecord(this.searchBox).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 确认导入
    resetData() {
      if (this.fileListExcel.length == 0) {
        this.uploadEexcel = true;
        return;
      } else {
        this.uploadEexcel = false;
      }
      let that = this;

      this.$refs["project"].validate((valid) => {
        if (valid) {
          that.repairBeforFileFormData1.append("workId", that.project.workId);
          indoor_intoVillage(that.repairBeforFileFormData1).then((res) => {
            that.$message.success("上传成功");
            that.loadList();
            // that.deriveDialog = false;
            let ret = JSON.parse(res.msg)
            console.log(ret)
            that.msg = ret.size.toString();
            that.msg_error = ret.err.toString();
          });
        }
      });
    },
    downloadempty() {
      const elink = document.createElement("a");

      elink.href =
        "https://gas-dev.oss-cn-beijing.aliyuncs.com/ZYMenuIcon/village.xls";
      const fileName = getNowFormatDate() + ".xls";

      elink.setAttribute("download", fileName);

      elink.style.display = "none";

      document.body.appendChild(elink);

      setTimeout(() => {
        elink.click();

        document.body.removeChild(elink);
      }, 66);
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
</style>